import { ReactComponent as Logo } from "assets/svgs/6sense-logo.svg";
import { uuidv4Regex } from "common/consts";
import { useInfoDisplayMessageWebSocket } from "common/hooks/useInfoDisplaySocket";
import { isFuture } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { MessageAndPlates } from "views/infoDisplay/MessageAndPlates";
import { OrientationContainer } from "views/infoDisplay/OrientationContainer";
import {
  DisplayPlate,
  PlateEntranceExitPayload,
  SettingsPayload,
} from "views/infoDisplay/types";
import { PaymentInfo } from "../../../backend/src/integration/anpr-edges/types";
import { InfoDisplayMessageType } from "../../../backend/src/integration/infoDisplay/types";
import packageJson from "../../package.json";

const directions = ["in", "out", "any"] as const;

type ConfigDirection = (typeof directions)[number];

const PARKKIMAKSU_PROVIDER = "PARKKIMAKSU";

export const InfoDisplayView = () => {
  const [settings, setSettings] = useState<SettingsPayload | undefined>(
    undefined
  );
  const handleSettingsMessage = useCallback((payload: SettingsPayload) => {
    setSettings(payload);
  }, []);
  useInfoDisplayMessageWebSocket(
    InfoDisplayMessageType.Settings,
    handleSettingsMessage
  );

  if (!settings) return null;
  return <ConfiguredView settings={settings} />;
};

const getFocusPlate = (plates: DisplayPlate[]) => {
  const now = Date.now();
  const futurePlates = plates.filter(
    ({ focusEnd }) => focusEnd.getTime() > now
  );
  const closest =
    futurePlates.length > 0
      ? futurePlates.reduce((prev, curr) => {
          return prev.focusEnd.getTime() < curr.focusEnd.getTime()
            ? prev
            : curr;
        })
      : undefined;
  return closest;
};

const useFocusPlate = (plates: DisplayPlate[]) => {
  const [focusPlate, setFocusPlate] = useState<DisplayPlate | undefined>(
    getFocusPlate(plates)
  );
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const closest = getFocusPlate(plates);
    setFocusPlate(closest);

    if (closest) {
      const diff = Math.max(0, closest.focusEnd.getTime() - Date.now());
      timeoutRef.current = setTimeout(() => {
        setFocusPlate(getFocusPlate(plates));
      }, diff);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [plates, focusPlate]);

  return focusPlate;
};

const useTimedPlates = (maxShowTimeMs: number) => {
  const [plates, setPlates] = useState<DisplayPlate[]>([]);

  const startTimer = useCallback(
    (plate: DisplayPlate) => {
      setTimeout(() => {
        setPlates((prev) => {
          if (prev.includes(plate)) return prev.filter((p) => p !== plate);
          return prev;
        });
      }, maxShowTimeMs);
    },
    [maxShowTimeMs]
  );

  const addPlate = useCallback(
    (newPlate: DisplayPlate) => {
      setPlates((prev) => [...prev, newPlate]);
      startTimer(newPlate);
    },
    [startTimer]
  );

  return { plates, addPlate };
};

const ConfiguredView = ({ settings }: { settings: SettingsPayload }) => {
  const { plates, addPlate } = useTimedPlates(settings.maxShowTimeMs);
  const plateFocus = useFocusPlate(plates);
  const { plates: overparkingPlates, addPlate: addOverparkingPlate } =
    useTimedPlates(settings.maxShowTimeMs);
  const overparkingFocus = useFocusPlate(overparkingPlates);
  const { search } = useLocation();

  const connectedLanesDirections = useMemo(() => {
    const iterator = search.matchAll(/(\?|&)lanesDirections=(.*?)(&|$)/g);
    const [, , matches = ""] = Array.from(iterator)[0] || [];
    const entries = matches.split(",").reduce((acc, v, idx, arr) => {
      const prev = arr[idx - 1];

      if (!(idx % 2)) return acc;

      const laneIdValid = prev === "any" || (prev && prev.match(uuidv4Regex));
      const directionValid = directions.includes(v as ConfigDirection);

      if (laneIdValid && directionValid) {
        acc.push({
          laneId: prev.toLowerCase(),
          direction: v.toLowerCase() as ConfigDirection,
        });
      } else {
        console.error(
          "Connected lanes and directions configuration value is not valid:",
          `${prev},${v}`
        );
      }

      return acc;
    }, [] as { laneId: string; direction: ConfigDirection }[]);

    return entries || [];
  }, [search]);

  const shouldNotify = useCallback(
    (payload: PlateEntranceExitPayload) => {
      const { laneId, direction } = payload;
      const matched = connectedLanesDirections
        .filter((ld) => ld.laneId === "any" || ld.laneId === laneId)
        .filter((ld) => ld.direction === "any" || ld.direction === direction);

      return !!matched.length;
    },
    [connectedLanesDirections]
  );

  const getPlateMessage = useCallback(
    (plate: PlateEntranceExitPayload) => {
      const message =
        plate.direction === "out"
          ? settings.byeMessage
          : settings.welcomeMessage;

      const { paymentInfo } = plate;

      if (!paymentInfo || paymentInfo.isContract) return message;

      if (plate.direction === "out" && checkParkingWasFree(paymentInfo))
        return `${message}\n${settings.freeParkingMessage}`;

      const { providerName, isPaid } = paymentInfo;
      const externalProvider =
        providerName && providerName.toUpperCase() !== PARKKIMAKSU_PROVIDER;
      if (externalProvider)
        return `${message}\n${settings.parkingOperatedByMessage.trim()} ${providerName}`;

      if (isPaid) return `${message}\n${settings.parkingPaidMessage}`;

      if (plate.direction === "out")
        return `${message}\n${settings.parkingNotPaidMessage}`;

      return message;
    },
    [
      settings.byeMessage,
      settings.parkingNotPaidMessage,
      settings.parkingOperatedByMessage,
      settings.parkingPaidMessage,
      settings.welcomeMessage,
      settings.freeParkingMessage,
    ]
  );

  const handlePlateEntranceExitMessage = useCallback(
    (payload: PlateEntranceExitPayload) => {
      if (connectedLanesDirections.length && !shouldNotify(payload)) return;
      const { remainingSlots } = payload;
      const hasCapacity = remainingSlots === undefined || remainingSlots >= 0;
      const showOverparking = payload.direction === "in" && !hasCapacity;
      const newPlate = {
        payload,
        focusEnd: new Date(Date.now() + settings.waitTimeMs),
        message: showOverparking
          ? settings.overparkingMessage
          : getPlateMessage(payload),
      };
      if (showOverparking) {
        addOverparkingPlate(newPlate);
      } else {
        addPlate(newPlate);
      }
    },
    [
      connectedLanesDirections.length,
      shouldNotify,
      settings.waitTimeMs,
      settings.overparkingMessage,
      getPlateMessage,
      addOverparkingPlate,
      addPlate,
    ]
  );
  useInfoDisplayMessageWebSocket(
    InfoDisplayMessageType.PlateEntranceOrExit,
    handlePlateEntranceExitMessage
  );

  return (
    <Container>
      <OrientationContainer>
        {overparkingPlates.length > 0 && (
          <MessageAndPlates
            hasCapacity={false}
            plates={overparkingPlates}
            focusPlate={overparkingFocus}
            maxShowTimeMs={settings.maxShowTimeMs}
            simultaneousPlates={settings.simultaneousPlates}
          />
        )}
        {overparkingPlates.length === 0 && plates.length > 0 && (
          <MessageAndPlates
            hasCapacity={true}
            plates={plates}
            focusPlate={plateFocus}
            maxShowTimeMs={settings.maxShowTimeMs}
            simultaneousPlates={settings.simultaneousPlates}
          />
        )}
        <Version>
          <span>{packageJson.version}</span>
          <Logo width="16" height="16" />
        </Version>
      </OrientationContainer>
    </Container>
  );
};

const checkParkingWasFree = (paymentInfo: PaymentInfo) => {
  if (typeof paymentInfo.freeParkingEnd === "undefined") return false;
  const freeUntilDate = new Date(paymentInfo.freeParkingEnd || "");
  return isNaN(freeUntilDate.getTime()) || isFuture(freeUntilDate);
};

const Container = styled.div`
  height: 100%;
  background: #000;
  cursor: none;
`;

const Version = styled.div`
  position: absolute;
  display: flex;
  flex-align: center;
  align-items: center;
  right: 0;
  bottom: 0;
  color: #fff;
  opacity: 0.3;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    fill: #bbb;
  }
`;
