import { useEventListener } from "@react-hookz/web";
import { ReactComponent as Check } from "assets/svgs/info-display-check.svg";
import { ReactComponent as Warning } from "assets/svgs/info-display-warning.svg";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { useOrientation } from "./useOrientation";

type Props = {
  message: string;
  hasCapacity: boolean;
};

const useAvailableSpace = () => {
  const { isVertical } = useOrientation();
  const getSpace = useCallback(
    () =>
      isVertical
        ? { width: window.innerHeight, height: window.innerWidth }
        : { width: window.innerWidth, height: window.innerHeight },
    [isVertical]
  );
  const [space, setAvailableSpace] = useState(getSpace());
  const handleResize = useCallback(() => {
    setAvailableSpace(getSpace());
  }, [getSpace]);
  useEventListener(window, "resize", handleResize);
  return space;
};

export const Message = ({ message, hasCapacity }: Props) => {
  const [title, ...rest] = message.split("\n");
  const space = useAvailableSpace();
  const widthBased = space.width / 9;
  const heightBased = space.height / 9;
  const fontSize = Math.min(widthBased, heightBased);
  const iconStyle = { width: fontSize * 1.2, height: fontSize * 1.2 };

  return (
    <MessageElement style={{ fontSize: `${fontSize}px` }}>
      <IconAndTitle>
        {hasCapacity ? (
          <GreenCheck width="46" height="46" style={iconStyle} />
        ) : (
          <BlueWarning width="46" height="46" style={iconStyle} />
        )}
        <Title>{title}</Title>
      </IconAndTitle>
      <ExtraLines>
        {rest.map((r, i) => (
          <Text key={i}>{r}</Text>
        ))}
      </ExtraLines>
    </MessageElement>
  );
};

const MessageElement = styled.div`
  color: #e8eaed;
  display: flex;
  flex-direction: column;
`;

const GreenCheck = styled(Check)`
  overflow: visible;
  .bg {
    fill: #7eb550;
    stroke: rgba(0, 0, 0, 0.1);
  }
  ${({ theme }) => `padding-right: 1${theme.vw};`}
`;

const BlueWarning = styled(Warning)`
  overflow: visible;
  .bg {
    fill: #3171d1;
    stroke: rgba(0, 0, 0, 0.1);
  }
  ${({ theme }) => `padding-right: 1${theme.vw};`}
`;

const ExtraLines = styled.div`
  text-align: center;
  color: #fff;
  font-size: 0.5em;
  line-height: 1.2;
`;

const IconAndTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  line-height: 1;
`;
const Text = styled.div``;
