import { DeviceTrigger } from "@asantech/common/asantech-client/types";
import { useAsync, useMap } from "@react-hookz/web";
import {
  CardColumn,
  CardRow,
  CardSubtitle,
  Dropdown,
  DropdownValue,
  Loader,
} from "components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanes } from "store/Lanes.context";
import styled from "styled-components";
import {
  createDeviceDropDownOptions,
  createTriggerDropDownOptions,
  fetchDeviceTriggers,
} from "./deviceTriggerDropdown";

export type Trigger = {
  deviceId: string;
  triggerId: string;
};

export type TriggerWithName = Trigger & {
  triggerName: string;
};

type Props = {
  value: Trigger | undefined;
  onChange: (trigger: TriggerWithName | null) => void;
};

const useTriggers = (deviceId: string | undefined) => {
  const map = useMap<string, DeviceTrigger[]>();
  const [triggerRequestState, { execute: doFetchDeviceTriggers }] = useAsync(
    async (deviceId: string) => {
      if (map.has(deviceId)) return;
      const triggers = await fetchDeviceTriggers(deviceId);
      map.set(deviceId, triggers);
    }
  );

  useEffect(() => {
    deviceId && void doFetchDeviceTriggers(deviceId);
  }, [deviceId, doFetchDeviceTriggers]);

  return {
    triggers: deviceId ? map.get(deviceId) || [] : [],
    loading: deviceId
      ? triggerRequestState.status === "not-executed" ||
        triggerRequestState.status === "loading"
      : false,
  };
};

export const TriggerMappingSelect = ({ value, onChange }: Props) => {
  const { devices, deviceFetchState } = useLanes();

  const [draftDevice, setDraftDevice] = useState<string>();
  const handleDeviceSelected = useCallback(
    async (value: DropdownValue) => {
      const device = value?.toString();
      setDraftDevice(device);
      if (!device) onChange(null);
    },
    [onChange]
  );

  const { loading: triggersLoading, triggers } = useTriggers(
    draftDevice || value?.deviceId
  );

  const handleTriggerSelected = useCallback(
    async (nextTrigger: DropdownValue) => {
      if (!nextTrigger) {
        onChange(null);
        return;
      }

      let nextDevice: string;
      if (draftDevice) {
        nextDevice = draftDevice;
      } else if (value?.deviceId) {
        nextDevice = value.deviceId;
      } else {
        console.error("Trigger selected, but no device.");
        return;
      }

      const triggerId = nextTrigger.toString();
      const triggerName = triggers.find(
        (t) => t.triggerId === triggerId
      )?.triggerName;
      if (!triggerName) {
        console.error("Selected trigger not found among loaded triggers.");
        return;
      }

      setDraftDevice(undefined);
      onChange({ deviceId: nextDevice, triggerId, triggerName });
    },
    [draftDevice, onChange, triggers, value?.deviceId]
  );

  const deviceDropDownOptions = useMemo(
    () => createDeviceDropDownOptions(devices),
    [devices]
  );
  const triggerDropDownOptions = useMemo(
    () => createTriggerDropDownOptions(triggers),
    [triggers]
  );

  const devicesLoading =
    deviceFetchState.status === "not-executed" ||
    deviceFetchState.status === "loading";

  return (
    <CardRow>
      <CardColumn>
        <CardSubtitle>Device</CardSubtitle>
        {devicesLoading ? (
          <DropdownLoader />
        ) : (
          <Dropdown
            onChange={handleDeviceSelected}
            value={draftDevice || value?.deviceId}
            options={deviceDropDownOptions}
            allowEmpty={true}
            size="largish"
          />
        )}
      </CardColumn>
      <CardColumn>
        <CardSubtitle>Device trigger</CardSubtitle>
        {triggersLoading ? (
          <DropdownLoader />
        ) : (
          <Dropdown
            onChange={handleTriggerSelected}
            value={draftDevice ? undefined : value?.triggerId}
            options={triggerDropDownOptions}
            allowEmpty={true}
            size="largish"
          />
        )}
      </CardColumn>
    </CardRow>
  );
};

const DropdownLoader = () => (
  <LoaderWrapper>
    <Loader size={30} />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  height: 50px;
`;
