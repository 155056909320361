import {
  LiveStreamRequestParams,
  LiveStreamResponse,
} from "@asantech/common/asantech-client/types";
import createClient from "@asantech/common/react/apiClient";
import { history } from "../App/history";
import { backendRootUrl } from "../config";
import { ApiEndpoints } from "./endpoints";
export * from "@asantech/common/react/apiClient";

export const { client, handleError } = createClient(backendRootUrl, history);

export async function getLiveStream(params: LiveStreamRequestParams) {
  const response = await client.post<LiveStreamResponse>(
    `${ApiEndpoints.LiveStream}`,
    params
  );
  return response.data;
}

export default client;
