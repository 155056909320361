import { Direction } from "../../detections/types";
import { PaymentInfo } from "../anpr-edges/types";

export enum InfoDisplayMessageType {
  PlateEntranceOrExit = "plateEntranceOrExit",
  Settings = "settings",
}

export type InfoDisplayMessages = {
  [InfoDisplayMessageType.PlateEntranceOrExit]: {
    plateText: string;
    vehicleId: string;
    laneId: string;
    direction?: Direction | null;
    remainingSlots?: number;
    paymentInfo?: PaymentInfo;
  };
  [InfoDisplayMessageType.Settings]: {
    welcomeMessage: string;
    byeMessage: string;
    parkingOperatedByMessage: string;
    parkingPaidMessage: string;
    parkingNotPaidMessage: string;
    overparkingMessage: string;
    freeParkingMessage: string;
    waitTimeMs: number;
    maxShowTimeMs: number;
    simultaneousPlates: number;
  };
};

export type WebSocketMessage<T extends InfoDisplayMessageType> = {
  type: T;
  payload: InfoDisplayMessages[T];
};
