import { Table, TableSelectableRow } from "components";
import { Fragment, useCallback, useState } from "react";

import styled from "styled-components";
import { settingsConfig } from "../../../../../backend/src/settings/config";
import { SettingConfig } from "../../../../../backend/src/settings/types";
import { ReactComponent as EditIcon } from "../../../assets/svgs/edit-icon.svg";
import { useSystemSettings } from "../../../common/hooks/useSystemSettings";
import { ValueEditor } from "./ValueEditor";

const config: Record<string, SettingConfig> = settingsConfig;

export const SystemSettings = () => {
  const [selected, setSelected] = useState<string>();

  const handleRowSelected = useCallback(
    (id: string) => {
      if (selected) {
        return;
      }
      setSelected(id);
    },
    [selected]
  );

  const unselect = useCallback(() => {
    setSelected(undefined);
  }, []);

  const { settings, updateSetting, loaded } = useSystemSettings();

  if (!settings.length && loaded) {
    return (
      <NoSettingsMessage>
        No settings available to be configured
      </NoSettingsMessage>
    );
  }
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>Setting</th>
            <th>Description</th>
            <th>Value(s)</th>
          </tr>
        </thead>
        <tbody>
          {settings.map((setting) => {
            const { codeName, values } = setting;
            const codeConfig = config[codeName];
            if (!codeConfig) {
              console.warn("codeConfig missing for", codeName);
              return null;
            }
            const isReadOnly = !!codeConfig.isReadOnly;

            return (
              <TableSelectableRow
                selected={codeName === selected}
                key={codeName}
                onClick={() => !isReadOnly && handleRowSelected(codeName)}
              >
                <TableCell>{codeName}</TableCell>
                <DescriptionCell>
                  {codeConfig.description}
                  {!!codeConfig.defaults?.length && (
                    <>
                      <br />
                      <span>
                        {" "}
                        Default value is{" "}
                        <Code>
                          {codeConfig.isMultiValue
                            ? codeConfig.defaults.join(", ")
                            : codeConfig.defaults[0]}
                        </Code>
                        .
                      </span>
                    </>
                  )}
                </DescriptionCell>
                <ValueCell readonly={isReadOnly} edited={codeName === selected}>
                  {codeName === selected ? (
                    <ValueEditor
                      setting={setting}
                      leaveEditMode={unselect}
                      onSave={updateSetting}
                    />
                  ) : (
                    <>
                      {values?.length ? (
                        values.map((value, idx) => (
                          <Fragment key={idx}>
                            {value}
                            <br />
                          </Fragment>
                        ))
                      ) : (
                        <>Tap to set value</>
                      )}
                      {!selected && codeName !== selected && !isReadOnly && (
                        <EditIcon />
                      )}
                    </>
                  )}
                </ValueCell>
              </TableSelectableRow>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  min-width: 900px;
  padding: 20px;
  margin: 20px auto;
`;

const NoSettingsMessage = styled.div`
  margin: 20px auto;
  text-align: center;
`;

const TableCell = styled.td`
  vertical-align: baseline;
  padding: 0 15px;
`;

const DescriptionCell = styled(TableCell)`
  && {
    word-break: break-word;
    max-width: 500px;
  }
`;

const ValueCell = styled(TableCell)<{ edited: boolean; readonly: boolean }>`
  && {
    overflow: ${({ edited }) => (edited ? "visible" : "hidden")};
    word-break: normal;
    width: 600px;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    cursor: ${({ readonly }) => (readonly ? "normal" : "pointer")};
  }
`;

const Code = styled.code`
  padding: 0px 4px;
  background: var(--info);
  border-radius: 4px;
`;
